import request from '@/utils/request'
export function getTenantTree(data) {
  return request({
    url: 'tenant/getTenantTree',
    method: 'post',
    data
  })
}

export function getAll(data) {
  return request({
    url: 'tenant/getAll',
    method: 'post',
    data
  })
}

export function switchOpen(data) {
  return request({
    url: 'tenant/switchOpen',
    method: 'post',
    data
  })
}
export function switchLimit(data) {
  return request({
    url: 'tenant/switchLimit',
    method: 'post',
    data
  })
}

export function switchAudit(data) {
  return request({
    url: 'tenant/switchAudit',
    method: 'post',
    data
  })
}


export function getShops(data) {
  return request({
    url: 'tenant/getShops',
    method: 'post',
    data
  })
}

export function save(data) {
  return request({
    url: 'tenant/save',
    method: 'post',
    data
  })
}

export function getSingle(data) {
  return request({
    url: 'tenant/getSingle',
    method: 'post',
    data
  })
}

export function del(data) {
  return request({
    url: 'tenant/delete',
    method: 'post',
    data
  })
}

